import { _postURL } from "@/api/index";
import { cookieExpireMaxAge, missionType, pageLimit } from "../config/site";
import _, { isEmpty } from "lodash";
import { getCookie, hasCookie, setCookie } from "cookies-next";
import { toast } from "@/libraries/swal";
import { pageLink } from "../config/site";
import { updatePath } from "@/action";
import moment from "moment";

// banner 
export const _getBanner = async(dispatch, lang = "en", store) =>{
    try {
        let prevStore = store?.path?.dataBanner ?? {}

        let result = await _postURL("/api/banner", {});
        if(!_.isEmpty(result?.data)){
            if(lang == "zh"){
                dispatch(updatePath({ key: "dataBanner", path: {...prevStore, ...{zh: result?.data}}}));
            } else {
                dispatch(updatePath({ key: "dataBanner", path: {...prevStore, ...{en: result?.data}}}));
            }
        } else {
            if(lang == "zh"){
                dispatch(updatePath({ key: "dataBanner", path: {...prevStore, ...{zh: {}}}}));
            } else {
                dispatch(updatePath({ key: "dataBanner", path: {...prevStore, ...{en: {}}}}));
            }
        }
    }
    catch (error) {
        console.log(error);
    }
}

// news
export const _getNewsCategoryData = async(dispatch, lang = "en", store) =>{
    try {
        let prevStore = store?.path?.dataNewsCategory ?? {}

        let req = {}
        // fetch news parent category
        let newsCategory = await _postURL("/api/news/getAllNewsCategory", {...req});
        if(!_.isEmpty(newsCategory.data)) {
            if(lang == "zh"){
                dispatch(updatePath({ key: "dataNewsCategory", path: {...prevStore, ...{zh: newsCategory.data}}}));
            } else {
                dispatch(updatePath({ key: "dataNewsCategory", path: {...prevStore, ...{en: newsCategory.data}}}));
            }
        } else {
            if(lang == "zh"){
                dispatch(updatePath({ key: "dataNewsCategory", path: {...prevStore, ...{zh: {}}}}));
            } else {
                dispatch(updatePath({ key: "dataNewsCategory", path: {...prevStore, ...{en: {}}}}));
            }
        }
    }
    catch (error) {
        console.log(error);
    }
}

export const _getNextNewsList = async({
    offset = 0,
    limit = pageLimit,
    activeNewsCategory,
    authorId,
    notinID,
    type,
    searchTitle,
    vehicleModel_id,
    vehicleBrand_id,
    willGoEmpty = false,
    noMergeData = false,
    vehicle_model_id
  }, setNewsList, setTotalRow) => {
    try {
        let req = {
            "limit": limit,
            "offset": offset,
            "news_category_id": activeNewsCategory,
            "author_id": authorId,
            "not_in_id": notinID,
            "type": type,
            "searchTitle": searchTitle,
            "vehicleModel_id": vehicleModel_id,
            "vehicleBrand_id": vehicleBrand_id,
            "vehicle_model_id": vehicle_model_id
        }
        let result = await _postURL("/api/news/getNews", {...req});

        if(!_.isEmpty(result?.data?.data) && noMergeData == true ){
            setNewsList(result?.data?.data);
        } else if(!_.isEmpty(result?.data?.data)){
            setNewsList((prevNewsList)=>[...prevNewsList, ...result.data.data]);
        } else if(_.isEmpty(result?.data?.data) && willGoEmpty == true){
            setNewsList([])
        }
        
        
        if(setTotalRow){
            setTotalRow(result.data.total_row);
        }
    }
    catch (error) {
        console.log(error);
    }
}

// vehicle
export const _getAllVehicleBrandList = async(dispatch) => {
    try {
        let req = {};
        let result = await _postURL("/api/vehicle/getAllVehicleBrandList", {...req});
        if(!_.isEmpty(result?.data)){
            dispatch(updatePath({ key: "dataAllVehicleBrandList", path: result?.data}));
        }
    } catch(error) {
        console.log(error);
    }
}

export const _getAllVehicleModelList = async(setVehicleModelList, {vehicleBrandId = undefined, vehicle_model_id_list, name = undefined}) =>{
    try {
        let req = {
            vehicle_brand_id: vehicleBrandId,
            vehicle_model_id_list: vehicle_model_id_list,
            name: name
        };
        let result = await _postURL("/api/vehicle/getAllModelList", {...req});
        if(!_.isEmpty(result?.data)){
            setVehicleModelList(result.data)
        } else {
            setVehicleModelList([])
        }
    } catch(error) {
        console.log(error);
    }
}

export const _getAllVehicleSeries = async(setVehicleSeries, {vehicle_model_id = undefined, not_in_variant_id}) =>{
    try{
        let req = {
            vehicle_model_id: vehicle_model_id,
            not_in_variant_id: not_in_variant_id
        };
        let result = await _postURL("/api/vehicle/getAllVehicleSeries", {...req});
        if(!_.isEmpty(result?.data)){
            setVehicleSeries(result.data)
        } else {
            setVehicleSeries([])
        }
    } catch(error) {
        console.log(error)
    }
}

export const _getAllVehicleVariant = async(setVehicleVariant, {vehicle_model_id = undefined}) =>{
    try{
        let req = {
            vehicle_model_id: vehicle_model_id
        };
        let result = await _postURL("/api/vehicle/getAllVehicleVariant", {...req});
        if(!_.isEmpty(result?.data)){
            setVehicleVariant(result.data)
        } else {
            setVehicleVariant([])
        }
    } catch(error) {
        console.log(error)
    }
}


export const _getAllVehicleTopList = async(dispatch) => {
    try {
        let req = {
            isOnlyBrand: false
        };
        let result = await _postURL("/api/vehicle/getAllVehicleTopList", {...req});

        if(!_.isEmpty(result?.data)){
            dispatch(updatePath({ key: "dataAllVehicleTopList", path: result?.data}));
        } else {
            dispatch(updatePath({ key: "dataAllVehicleTopList", path: {}}));
        }
    } catch(error) {
        console.log(error);
    }
}

export const _handleOnClickCompare = (value, type, setCompareList) => {
    if(type == 'compare'){
        setCompareList((prevData)=>{
            if(prevData.length <= 3){
                const newDataItem = {
                    variant_id: value.variant_id,
                    name: value.name,
                    model_image_path: value.model_image_path,
                    compare_custom_url: value.compare_custom_url
                };
                if(!_.isEmpty(prevData)){
                    if(!_.some(prevData, newDataItem)){
                        let newData = [...prevData, newDataItem]
                        setCookie('compare_list', newData) 
                        return newData
                    } else {
                        return prevData
                    }
                } else {
                    setCookie('compare_list', [newDataItem]) 
                    return [newDataItem]
                }
            } else {
                toast.info("Max 4 variant to compare")
            }
            return prevData
        })
    }
}
export const _handleOnClickCancelCompare = (value, setCompareList) =>{
    setCompareList((prevData)=>{
        let filterData = _.filter(prevData, (item) => item?.variant_id != value);
        setCookie('compare_list', filterData) 
        return filterData
    })
}

export const _handleOnClickGoCompare = (value, router) => {
    const queryString = value.map((item, key) => `${item.compare_custom_url}`).join('-vs-');
    const queryVariantId = value.map((item, key) => `v${key+1}=${item.variant_id}`).join('&');
    if(value.length <= 1){
        toast.info("Min 2 variant to comapare")
    } else {
        router.push(`${pageLink.compare.compareMidUrl}${queryString}?${queryVariantId}`)
    }
}

// dealer
export const _getDealerList = async({vehicleBrandId, limit, offset, not_in_id, brand_custom_url, state, willMergeData = false}, setDealerList, setTotalRow) =>{
    try{
        let req = {
            vehicle_brand_id: vehicleBrandId,
            limit: limit,
            offset: offset,
            not_in_id: not_in_id,
            brand_custom_url: brand_custom_url,
            state: state
        };
        let result = await _postURL("/api/dealer/getDealerList", {...req});
        if(!_.isEmpty(result?.data?.data) && willMergeData == true){
            setDealerList((prevData)=>[...prevData, ...result?.data?.data])
        } else if(!_.isEmpty(result?.data?.data)){
            setDealerList(result?.data?.data)
        } else {
            setDealerList([])
        }
        if(setTotalRow){
            setTotalRow(result?.data?.total_row)
        }
    } catch(error) {
        console.log(error)
    }
}

export const _handleOnClickDealerCall = (value, setPhoneNumberList, setPhoneCallOpen) =>{
    let arrayDealerNumber = {}
    let showRoomNo = JSON.parse(value?.show_room_no)
    let serviceNo = JSON.parse(value?.service_no)
    let bodyPaintNo = JSON.parse(value?.body_paint_no)
    
    if(!(showRoomNo.length === 0 || (showRoomNo.length === 1 && showRoomNo[0] === ''))){
        arrayDealerNumber = {"showRoomNo": showRoomNo}
    }
    if(!(serviceNo.length === 0 || (serviceNo.length === 1 && serviceNo[0] === ''))){
        arrayDealerNumber = {...arrayDealerNumber, "serviceNo": serviceNo}
    }
    if(!(bodyPaintNo.length === 0 || (bodyPaintNo.length === 1 && bodyPaintNo[0] === ''))){
        arrayDealerNumber = {...arrayDealerNumber, "bodyPaintNo": bodyPaintNo}
    }

    setPhoneNumberList(arrayDealerNumber)
    setPhoneCallOpen(true)
}

// author
export const _getAllAuthor = async(setAuthorList, {author_id_list, vehicle_model_id, limit, offset }) => {
    try{
        let req = {
            author_id_list: author_id_list,
            vehicle_model_id: vehicle_model_id,
            limit: limit,
            offset: offset
        };
        let result = await _postURL("/api/author/getAllAuthor", {...req});
        if(!_.isEmpty(result?.data)){
            setAuthorList(result.data)
        } else {
            setAuthorList([])
        }
    } catch(error) {
        console.log(error)
    }
}

// review
export const _getUserReview = async(setUserReviewList, {brand_custom_url, model_custom_url, limit, offset}) => {
    try{
        let req = {
            brand_custom_url: brand_custom_url,
            model_custom_url: model_custom_url,
            limit: limit,
            offset: offset,
        };
        let result = await _postURL("/api/review/getUserReview", {...req});
        if(!_.isEmpty(result?.data)){
            let group_rating = _.groupBy(result?.data?.data, 'rating')
            setUserReviewList({...result.data, group_rating: group_rating})
        } else {
            setUserReviewList([])
        }
    } catch(error) {
        console.log(error)
    }
}

export const _getUserTyreReview = async(setUserReviewList, {brand_custom_url, model_custom_url, limit, offset}) => {
    try{
        let req = {
            brand_custom_url: brand_custom_url,
            model_custom_url: model_custom_url,
            limit: limit,
            offset: offset,
        };
        let result = await _postURL("/api/review/getUserTyreReview", {...req});
        if(!_.isEmpty(result?.data)){
            let group_rating = _.groupBy(result?.data?.data, 'rating')
            setUserReviewList({...result.data, group_rating: group_rating})
        } else {
            setUserReviewList([])
        }
    } catch(error) {
        console.log(error)
    }
}

// speed test 
export const _getSpeedTestRanking = async(dispatch) =>{
    try {
        let ranking = await _postURL("/api/speedtest/getSpeedTestRanking", {});
        if(!_.isEmpty(ranking.data)) {
            dispatch(updatePath({ key: "dataSpeedTestRanking", path: ranking.data}));
        } else {
            dispatch(updatePath({ key: "dataSpeedTestRanking", path: {}}));
        }
    }
    catch (error) {
        console.log(error);
    }
}

// placement
export const _getPlacement = async(dispatch) => {
    try{
        let req = {};
        
        let result = await _postURL("/api/placement/getAllPlacement", {...req});

        if(!_.isEmpty(result.data)) {
            const transformedObject = _.keyBy(result.data, 'area');
            dispatch(updatePath({ key: "dataPlacement", path: transformedObject}));
        } else {
            dispatch(updatePath({ key: "dataPlacement", path: {}}));
        }
    } catch(error) {
        console.log(error)
    }
}

export const _increasePlacementImpression = async({placement_id}) => {
    try{
        let req = {
            id: placement_id
        };
        
        await _postURL("/api/placement/increasePlacementImpression", {...req});
    } catch(error) {
        console.log(error)
    }
}

export const _increasePlacementClick = async({placement_id}) => {
    try{
        let req = {
            id: placement_id
        };
        
        await _postURL("/api/placement/increasePlacementClick", {...req});
    } catch(error) {
        console.log(error)
    }
}

// common
export const _increaseView = async(news_id) =>{
    try {
        let req = {
            "id": news_id
        }
        let videoView = await _postURL("/api/news/increaseVideoView", {...req});
    }
    catch (error) {
        console.log(error);
    }
}

export const _modelName = (brand, model) =>{
    const startIndex = model.indexOf(brand) + brand.length;
    const result = model.substring(startIndex).trim();

    return result
}

export const _numToPrice = (price) => {
    const result = parseInt(price) > 0 ? `RM ${parseInt(price).toLocaleString('en-US')}` : "TBC"
    return result
}

export const _numToPriceBetween = (minPrice, maxPrice) => {
    const intMinPrice = parseInt(minPrice)
    const intMaxPrice = parseInt(maxPrice)
    if(intMinPrice == 0 || intMaxPrice == 0){
        return "TBC"
    } else if(intMinPrice == intMaxPrice){
        const result = `RM ${parseInt(intMaxPrice).toLocaleString('en-US')}`
        return result
    } else {
        const result = `RM ${parseInt(intMinPrice).toLocaleString('en-US')} - RM ${parseInt(intMaxPrice).toLocaleString('en-US')}`
        return result
    }
}

export const _stringToLink = (string) =>{
    const result = _.toLower(string).replace(' ', '-');
    return result
}

export const _toDecimalWithoutRounding = (number, decimal) => {
    const numDecimal = Math.pow(10, decimal)
    const formattedNumber = Math.trunc(number * numDecimal) / numDecimal
    return formattedNumber.toFixed(decimal)
};

export const _getMinMaxValueArrange = (min_num, max_num, decimal = 0) =>{
    if((min_num <= 0 && max_num <= 0) || (min_num == "-" && max_num == "-")){
        return "-"
    } else if(min_num <= 0 && max_num > 0){
        const result = `${_toDecimalWithoutRounding(max_num, decimal)}`
        return result
    } else if (min_num > 0 && max_num > 0){
        if(min_num == max_num){
            const result = `${_toDecimalWithoutRounding(max_num, decimal)}`
            return result
        } else {
            const result = `${_toDecimalWithoutRounding(min_num, decimal)} - ${_toDecimalWithoutRounding(max_num, decimal)}`
            return result
        }
    }
}

export const _getTransmissionArray = (value = []) => {
    let result = "-"
    if(!_.isEmpty(value)){
        result = _.chain(value)
        .uniqBy()
        .without("-")
        .join(' / ')
        .value()
    }
    if(_.isEmpty(result)){
        result = "-"
    }
    return result
}

export const _convertRatingAverage = (totalRating, totalReview) => {
    if(totalReview > 0){
        return ((totalRating / (totalReview * 5)) * 5).toFixed(1)
    }
    return 0
}
export const _convertRatingAveragePercentage = (totalRating, totalReview) => {
    if(totalReview > 0){
        return ((totalRating / (totalReview * 5)) * 100).toFixed(0)
    }
    return 0
}

export const generateFilterToUrl = (filter) => {
    let filterLink = '';
    // Brand
    if (!_.isEmpty(filter.brand)) {
        let brand = _.join(filter.brand, ',');
        filterLink += 'brand='+brand+'&';
    }
    // Price
    if (!_.isEmpty(filter.price)) {
        let price = _.map(filter.price, 'label').join(',')
        filterLink += 'price='+price+'&';
        // filterLink += 'min_price='+filter.price[0]+'&max_price='+filter.price[1]+'&';
    }
    // Body Type
    if (!_.isEmpty(filter.bodyType)) {
        let bodyType = _.join(filter.bodyType, ',');
        filterLink += 'bodytype='+bodyType+'&';
    }
    // Segment
    if (!_.isEmpty(filter.segment)) {
        let segment = _.join(filter.segment, ',');
        filterLink += 'segment='+segment+'&';
    }
    // Transmission
    if (!_.isEmpty(filter.transmission)) {
        let transmission = _.join(filter.transmission, ',');
        filterLink += 'transmission='+transmission+'&';
    }
    // Fuel
    if (!_.isEmpty(filter.fuel)) {
        let fuel = _.join(filter.fuel, ',');
        filterLink += 'fuel='+fuel+'&';
    }
    // Seat
    if (!_.isEmpty(filter.seats)) {
        let seats = _.join(filter.seats, ',');
        filterLink += 'seats='+seats+'&';
    }
    // Driveline
    if (!_.isEmpty(filter.driveline)) {
        let driveline = _.join(filter.driveline, ',');
        filterLink += 'driveline='+driveline+'&';
    }
    // Features
    if (!_.isEmpty(filter.featuresSpecs)) {
        let featuresSpecs = _.join(filter.featuresSpecs, ',');
        filterLink += 'featuresSpecs='+featuresSpecs+'&';
    }

    return filterLink.substring(0, filterLink.length - 1);
}

// aws s3
export const _getS3ListFileImage = async(setImageList, {s3key = undefined}) => {
    try {
        if(!_.isEmpty(s3key)){
            let req = {
                s3key: s3key
            }
            let imageList = []
            let result = await _postURL("/api/s3/getS3ListFile", {...req});

            _.map(result, (value, key)=>{
                imageList.push(process.env.NEXT_PUBLIC_FS_URL + value?.Key)
            })

            setImageList(imageList)
        } else {
            throw ("Empty")
        }
    } catch(error) {
        console.log(error)
    }
}

// sftp
export const _getSFTPListFileImage = async(setImageList, {pathName = undefined}) => {
    try {
        if(!_.isEmpty(pathName)){
            let req = {
                pathName: pathName
            }

            let result = []
            let imageList = []
            result = await _postURL("/api/sftp/getSFTPListFile", {...req});

            if(!_.isEmpty(result)){
                _.chain(result)
                .orderBy('name', 'asc')
                .map((value, key)=>{
                    imageList.push(`${process.env.NEXT_PUBLIC_FS_URL + process.env.NEXT_PUBLIC_ENV}/images/news/${pathName}/content_image/${value?.name}`)
                })
                .value()
            }

            setImageList(imageList)
        } else {
            throw ("Empty")
        }
    } catch(error) {
        console.log(error)
    }
}

export const _UploadSFTPImage = async(imageFile) => {
    try {
        if(!_.isEmpty(imageFile)){
            if(_.isEmpty(imageFile.upload_path)) throw 'Empty upload path'
            // let req = {
            //     upload_path: imageFile.upload_path
            // }
            const formData = new FormData();
            formData.append("file", imageFile.file);
            formData.append("uploadPath", imageFile.upload_path);
            
            let result = await _postURL("/api/sftp/uploadSFTPImage", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

        } else {
            throw ("Empty")
        }
    } catch(error) {
        console.log(error)
    }
}

// tyre
export const _getAllTyreBrandList = async(dispatch) => {
    try {
        let req = {};
        let result = await _postURL("/api/tyre/getAllTyreBrandList", {...req});
        if(!_.isEmpty(result?.data)){
            dispatch(updatePath({ key: "dataAllTyreBrandList", path: result?.data}));
        }
    } catch(error) {
        console.log(error);
    }
}

export const _getAllTyreTopList = async(dispatch) => {
    try {
        let req = {
            isOnlyBrand: false
        };
        let result = await _postURL("/api/tyre/getAllTyreTopList", {...req});

        if(!_.isEmpty(result?.data)){
            dispatch(updatePath({ key: "dataAllTyreTopList", path: result?.data}));
        } else {
            dispatch(updatePath({ key: "dataAllTyreTopList", path: {}}));
        }
    } catch(error) {
        console.log(error);
    }
}

export const _getAllTyreModelList = async(setTyreModelList, {tyreBrandId = undefined, tyre_model_id_list, name = undefined}) =>{
    try {
        let req = {
            tyre_brand_id: tyreBrandId,
            tyre_model_id_list: tyre_model_id_list,
            name: name
        };
        let result = await _postURL("/api/tyre/getAllModelList", {...req});
        if(!_.isEmpty(result?.data)){
            setTyreModelList(result.data)
        } else {
            setTyreModelList([])
        }
    } catch(error) {
        console.log(error);
    }
}

export const _getAllTyreModelVariant = async(setTyreVariant, {tyre_model_id = undefined}) =>{
    try {
        let req = {
            tyre_model_id: tyre_model_id
        };
        let result = await _postURL("/api/tyre/getAllTyreModelVariant", {...req});
        if(!_.isEmpty(result?.data)){
            setTyreVariant(result.data)
        } else {
            setTyreVariant([])
        }
    } catch(error) {
        console.log(error);
    }
}

export const _generateTyreFilterToUrl = (filter, filterType) => {
    let filterLink = '';
    if(filterType == 'by_vehicle') {
        if(!_.isEmpty(filter.model)){
            filterLink = 'search_type=by_vehicle&';
        }

        // Model 
        if (!_.isEmpty(filter.model)) {
            let model = _.join(filter.model, ',');
            filterLink += 'model='+model+'&';
        }
    }else if(filterType == 'by_filter') {
        if(!_.isEmpty(filter.body_type) || !_.isEmpty(filter.rim_diameter) || !_.isEmpty(filter.driving_experience)){
            filterLink = 'search_type=by_filter&';
        }
        
        // Body Type
        if (!_.isEmpty(filter.body_type)) {
            let body_type = _.join(filter.body_type, ',');
            filterLink += 'body_type='+body_type+'&';
        }

        // Rim Diameter
        if (!_.isEmpty(filter.rim_diameter)) {
            let rim_diameter = _.join(filter.rim_diameter, ',');
            filterLink += 'rim_diameter='+rim_diameter+'&';
        }

        // Driving experience
        if (!_.isEmpty(filter.driving_experience)) {
            let driving_experience = _.join(filter.driving_experience, ',');
            filterLink += 'driving_experience='+driving_experience+'&';
        }
    }
    else {
        // Do nothing
    }

    return filterLink.substring(0, filterLink.length - 1);
}

export const _getAllTyreSize = async(setAllTyreSize) => {
    try {
        let req = {};
        let result = await _postURL("/api/tyre/getAllTyreSize", {...req});
        if(!_.isEmpty(result?.data)){
            setAllTyreSize(result.data)
        } else {
            setAllTyreSize([])
        }
    } catch(error) {
        console.log(error);
    }
}

// EV
export const _getGoogleMapEvMarker = async(dispatch, store, setEvStationNearBy=()=>{}, {swlat, nelat, swlng, nelng}) => {
    try{
        let prevStore = store?.path?.dataEvstationMarker ?? {}

        let req = {
            swlat: swlat,
            nelat: nelat,
            swlng: swlng,
            nelng: nelng
        };
        
        let result = await _postURL("/api/evstation/getListing", {...req});

        if(!_.isEmpty(result.data)) {
            dispatch(updatePath({ key: "dataEvstationMarker", path: {...prevStore, ...result.data.data}}));
            setEvStationNearBy(result.data.data)
        }
    } catch(error) {
        console.log(error)
    }
}

export const _searchGoogleMapEvMarker = async({address}, setData) => {
    try{
        let req = {
            address: address
        };
        
        let result = await _postURL("/api/evstation/getListing", {...req});
        if(!_.isEmpty(result.data)) {
            setData(result.data.data)
        }
    } catch(error) {
        console.log(error)
    }
}

// daily mission
export const _getDailyCheckIn = async(dispatch) => {
    try {
        let req = {

        };
        let result = await _postURL("/api/dailycheckin/getListing", {...req});

        if(!_.isEmpty(result?.data)){
            const currentDate = moment().format('YYYY-MM-DD');

            let user_daily_check_in = _.orderBy(result?.data?.user_daily_check_in,(['on_days', 'asc']))
            dispatch(updatePath({ key: "dataDailyCheckIn", path: { 'daily_check_in_batch': result?.data?.daily_check_in_batch, 'user_daily_check_in': user_daily_check_in, 'getDataDate': currentDate}}));
        } else {
            dispatch(updatePath({ key: "dataDailyCheckIn", path: {}}));
        }
    } catch(error) {
        console.log(error);
    }
}

export const _clickCheckIn = async(dispatch) => {
    try {
        let req = {

        };
        const result = await _postURL("/api/dailycheckin/clickCheckIn", {...req});
        return result
    } catch(error) {
        console.log(error);
    }
}

export const _getVehicleBrandArchiveList = async(dispatch) => {
    try {
        let req = {};
        let result = await _postURL("/api/vehicle/getBrandArchive", {...req});
        if(!_.isEmpty(result?.data)){
            dispatch(updatePath({ key: "dataAllVehicleBrandArchiveList", path: result?.data}));
            return result?.data
        }
    } catch(error) {
        console.log(error);
    }
}

export const _getVehicleModelArchiveList = async({vehicle_brand_id, brand}) => {
    try {
        if(_.isEmpty(vehicle_brand_id) || _.isEmpty(brand))throw ("Empty")
        let req = {
            vehicle_brand_id: vehicle_brand_id,
            brand: brand
        };
        let result = await _postURL("/api/vehicle/getModelArchive", {...req});
        if(!_.isEmpty(result?.data)){
            return result?.data
        }
    } catch(error) {
        console.log(error);
    }
}

export const _getVehicleVariantArchiveList = async({vehicle_model_id, model, brand}) => {
    try {
        if(_.isEmpty(vehicle_model_id) || _.isEmpty(model))throw ("Empty")
        let req = {
            vehicle_model_id: vehicle_model_id,
            model: model,
            brand: brand
        };
        let result = await _postURL("/api/vehicle/getVariantArchive", {...req});
        if(!_.isEmpty(result?.data)){
            return result?.data
        }
    } catch(error) {
        console.log(error);
    }
}

export const _getMissionAddVehicle = async(dispatch) => {
    try{
        let req = {
            mission_type: missionType.addVehicleMission,
        };
        let result = await _postURL("/api/mission/getMission", {...req});

        if(!_.isEmpty(result?.data?.data?.[0])){
            dispatch(updatePath({ key: "dataMissionAddVehicle", path: result?.data?.data?.[0]}));
        } else {
            dispatch(updatePath({ key: "dataMissionAddVehicle", path: {}}));
        }
    } catch(error) {
        console.log(error)
    }
}

export const _getUserVehicle = async(dispatch) => {
    try {
        let req = {};
        let result = await _postURL("/api/user/getUserVehicle", {...req});
        
        if(!_.isEmpty(result?.data)){
            let defaultData = {}
            if(result?.data?.data.length >= 1){
                defaultData = result?.data?.data[0]
                if(result?.data?.data.length >= 1){
                    defaultData = _.find(result?.data?.data, { 'is_default': '1' })
                }
            }
            dispatch(updatePath({ key: "dataUserVehicle", path: {...result?.data, 'defaultData': defaultData}}));
        }
    } catch(error) {
        console.log(error);
    }
}

// comment
export const _getIfUserLikeNews = async(ref_table, ref_id, setCommentLikeStatus) => {
    try {
        let req = {
            ref_table: ref_table,
            ref_id: ref_id
        };
        let result = await _postURL("/api/comment/getIfUserLikeNews", {...req});
        if(!_.isEmpty(result?.data)){
            setCommentLikeStatus(true)
        } else {
            setCommentLikeStatus(false)
        }
    } catch(error) {
        console.log(error);
    }
}

export const _getAllComment = async(ref_table, ref_id, limit, offset, setCommentList) => {
    try {
        let req = {
            ref_table: ref_table,
            ref_id: ref_id,
            limit: limit,
            offset: offset
        };
        let result = await _postURL("/api/comment/getAllComment", {...req});
        if(!_.isEmpty(result?.data)){
            setCommentList(result.data)
        } else {
            setCommentList([])
        }
    } catch(error) {
        console.log(error);
    }
}

export const _getUserProfile = async(setUserData) =>{
    try {
        let req = {};
        let result = await _postURL("/api/user/getUserProfile", {...req});
        if(!_.isEmpty(result?.data)){
            let user = hasCookie("user") && JSON.parse(getCookie("user"));
            let newUserData = {...user, ...result.data}

            setCookie('user', JSON.stringify(newUserData), {maxAge: cookieExpireMaxAge});
            setUserData({ ...user, ...result.data })
        } else {
            setUserData({})
        }
    } catch(error) {
        console.log(error);
    }
}

export const _getAllVoucher = async(setVoucher) => {
    try{
        let req = {};
        let result = await _postURL("/api/voucher/getAllVoucher", {...req});
        if(!_.isEmpty(result?.data)){
            setVoucher(result?.data)
        } else {
            setVoucher([])
        }
    } catch(error){
        console.log(error);
    }
}

export const _getAllMyRedemption = async(setMyRedemption) => {
    try{
        let req = {};
        let result = await _postURL("/api/voucher/getAllMyRedemption", {...req});
        if(!_.isEmpty(result?.data[1]) || !_.isEmpty(result?.data[2]) || !_.isEmpty(result?.data[3])){
            setMyRedemption(result?.data)
        } else {
            setMyRedemption({})
        }
    } catch(error){
        console.log(error);
    }
}

export const _redeemVoucher = async(voucherId) => {
    try{
        let req = {
            voucher_id: voucherId
        };
        let result = await _postURL("/api/voucher/redeemVoucher", {...req});
        if(result?.['insert_id'] == true){
            return true
        }
    } catch(error){
        toast.error(error)
        console.log(error);
    }
}

export const _useVoucher = async(user_voucher_id) => {
    try{
        let req = {
            user_voucher_id: user_voucher_id
        };
        let result = await _postURL("/api/voucher/useVoucher", {...req});
        if(result?.['affected_row'] == 1){
            return true
        }
    } catch(error){
        toast.error(error)
        console.log(error);
    }
}

export const _getUserAccountLog = async({
        offset = 0,
        limit = pageLimit,
        prev_day = undefined, 
        type = undefined, 
        sign_of_the_amount = undefined
    }, setAccountLogList, setTotalRow) => {
    try{
        let req = {
            "limit": limit,
            "offset": offset,
            prev_day: !isEmpty(prev_day) ? prev_day : null,
            type: !isEmpty(type) ? type : null,
            sign_of_the_amount: !isEmpty(sign_of_the_amount) ? sign_of_the_amount : null,
        };
        let result = await _postURL("/api/user/getUserAccountLog", {...req});
        if(!isEmpty(result?.['data'])){
            setAccountLogList((prevAccountList) => {
                if(prevAccountList == false){
                    return [...result?.['data']?.['data']];
                } else {
                    return [...prevAccountList, ...result?.['data']?.['data']];
                }
            });
            setTotalRow(result?.['data']?.['total_row'])
        }
    } catch(error){
        toast.error(error)
        console.log(error);
    }
}

export const removeIsWebviewPath = (urlLink) => {
    if (typeof urlLink !== 'string' || !urlLink.includes('?')) {
        return urlLink; 
    }
    
    const queryParams = new URLSearchParams(urlLink.split('?')[1]);
    queryParams.delete('isWebview');
    queryParams.delete('isStandAlone');
    const newPath = `${urlLink.split('?')[0]}${queryParams.toString() ? '?' + queryParams.toString() : ''}`;

    return newPath
}