import Head from "next/head";
import { isEmpty } from "lodash";

export function StandardMeta({ title = "", description = "", keyword = "", canonical = "" }) {
    return (
        <Head>
            { !isEmpty(title) && <meta key="standard-title" name="title" content={title} /> }
            { !isEmpty(description) && <meta key="standard-description" name="description" content={description} /> }
            { !isEmpty(keyword) && <meta key="standard-keywords" name="keywords" content={keyword} /> }
            { !isEmpty(canonical) && <link key="standard-canonical" rel="canonical" href={canonical} /> }
        </Head>
    );
}

export function GoogleMeta({ title = "", description = "", image = "" }) {
    return (
        <Head>
            { !isEmpty(title) && <meta key="google-name" itemProp="name" content={title} /> }
            { !isEmpty(description) && <meta key="google-description" itemProp="description" content={description} /> }
            { !isEmpty(image) && <meta key="google-image" itemProp="image" content={image} /> }
        </Head>
    );
}

export function FacebookMeta({ locale = "en_US", type = "", url = "", title = "", description = "", site_name = "", image = "", imageWidth = 512, imageHeight = 512 }) {
    return (
        <Head>
            <meta key="og:locale" property="og:locale" content={locale} />
            { !isEmpty(type) &&  <meta key="og:type" property="og:type" content={type} /> }
            { !isEmpty(url) &&  <meta key="og:url" property="og:url" content={url} /> }
            { !isEmpty(title) &&  <meta key="og:title" property="og:title" content={title} /> }
            { !isEmpty(description) &&  <meta key="og:description" property="og:description" content={description} /> }
            { !isEmpty(site_name) &&  <meta key="og:site_name" property="og:site_name" content={site_name} /> }
            { !isEmpty(image) &&  <meta key="og:image" property="og:image" content={image} /> }
            { !isEmpty(image) &&  <meta key="og:image:secure_url" property="og:image:secure_url" content={image} /> }
            <meta key="og:image:width" property="og:image:width" content={imageWidth} />
            <meta key="og:image:height" property="og:image:height" content={imageHeight} />
            { !isEmpty(title) &&  <meta key="og:image:alt" property="og:image:alt" content={title} /> }
        </Head>
    );
}

export function TwitterMeta({ card = "summary_large_image", site = "@keyauto", url = "", title = "", description = "", image = "" }) {
    return (
        <Head>
            { !isEmpty(card) &&  <meta key="twitter:card" property="twitter:card" content={card} /> }
            <meta key="twitter:site" property="twitter:site" content={site} />
            { !isEmpty(url) &&  <meta key="twitter:url" name="twitter:url" content={url} /> }
            { !isEmpty(title) &&  <meta key="twitter:title" property="twitter:title" content={title} /> }
            { !isEmpty(description) &&  <meta key="twitter:description" property="twitter:description" content={description} /> }
            { !isEmpty(image) &&  <meta key="twitter:image" property="twitter:image" content={image} /> }
        </Head>
    );
}

export function AllMeta({ title = "", description = "", keyword = "", image = "", currentPageUrl = "", locale = "en_US", type = "", twitterHandle = "@keyauto", imageWidth = 512, imageHeight = 512, children }) {
    return (
        <>
            <StandardMeta title={title} description={description} keyword={keyword} canonical={currentPageUrl} />
            <GoogleMeta title={title} description={description} image={image} />
            <FacebookMeta locale={locale} type={type} url={currentPageUrl} title={title} description={description} site_name={title} image={image} imageWidth={imageWidth} imageHeight={imageHeight}  />
            <TwitterMeta site={twitterHandle} url={currentPageUrl} title={title} description={description} image={image} />
            <Head>{ children }</Head>
        </>
    );
}