import React from 'react';
import * as Sentry from "@sentry/react";
import Dayjs from 'dayjs';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error = '', errorInfo = '') {
    // You can also log the error to an error reporting service
    if(error){
        Sentry.captureMessage("( Client Side Error )"+error, errorInfo);
        console.log(error, errorInfo);
        this.setState({ hasError: true });
    }
  }

  refreshPage = () => {
    window.location.reload();
  }

  backPage = () => {
    window.location.href = '/';
  }

  render() {
    let userAgent = '';
    if (typeof navigator == 'object' && typeof navigator != 'undefined') {
      userAgent = navigator?.userAgent;
    }

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="container">
            <div className="error-wrapper">
            <h1>Oops! Something went wrong. </h1>
            <p>Don't worry, our team has been notified and is actively working to resolve the issue."</p>
            <div className="action-btn">
              <a className="standard-btn" onClick={this.refreshPage}>Try Again</a>
              <a className="standard-btn" onClick={this.backPage}>Back to Home</a>
            </div>
            <br/>
            <br/>
            <br/>
            <p>{userAgent}<br/>{new Date().toLocaleString()}</p>
            </div>
        </div>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
