import { Loader, LoadingOverlay } from "@mantine/core"
import { forwardRef, memo, useImperativeHandle, useState } from "react"

export const LoadMore = (props) =>{
    const { t } = props
    return (
        <div className='loadingMore'>
            <Loader color="gray" size="xs" />
            <p className='fontSize_12 loadingMore_text'>
                {t('loading')}
            </p>
        </div>
    )
} 

export const FullScreenLoading =  memo(forwardRef(function FullScreenLoading({defaultLoadingState = false}, ref) {
    const [isLoading, setIsLoading] = useState(defaultLoadingState)

    useImperativeHandle(ref, () => ({
        setIsLoading: (value) => setIsLoading(value),
    }));

    // if(isLoading == false){
    //     return <></>
    // }
    return (
        <div className="mainLoaderContainer" style={{display: isLoading == false ? 'none' : 'block'}}>
            <LoadingOverlay visible={isLoading} zIndex={1000} overlayBlur={10} />
        </div>
    )
}))