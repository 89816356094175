import { useEffect, useLayoutEffect, useMemo } from "react";
import { _getURL } from "@/api/index";
import Redux, { updatePath } from "@/action";
import { _getBalances } from "@/libraries/fund";
import { useViewportSize } from "@mantine/hooks";
import Footer from "./footer";
// import Navbar from "./navbar";
import Header from "./header";
import { useRouter } from "next/router";
import { mobileViewWidth } from "../../config/site";

export default function Layout(props) {
    const { children } = props;
    const { t, session={} } = children.props;
    const { store, dispatch } = Redux();
    const { height, width } = useViewportSize();
    const router = useRouter();

    useEffect(() => {
        function updateSize() {
            if (window.innerWidth <= mobileViewWidth) {
                location.reload();
            }
        }
        window.addEventListener('resize', updateSize);

        const queryParams = new URLSearchParams(router.asPath.split('?')[1]);
        const isWebview = queryParams.get('isWebview');
        const isStandAlone = queryParams.get('isStandAlone');

        if(store?.path?.isLoadedInFlutterApp == undefined){
            dispatch(updatePath({ key: "isLoadedInFlutterApp", path: (isWebview == "true") ? true : false}));
        }
        if(store?.path?.isStandaloneInFlutterApp == undefined){
            dispatch(updatePath({ key: "isStandaloneInFlutterApp", path: (isStandAlone == "true") ? true : false}));
        }

        return () => window.removeEventListener('resize', updateSize);
    }, []);
    
    return <>
        {/* <Header {...children.props} /> */}
        {/* <!-- main start here --> */}
        <div className="phoneFrameContainer">
            <div className="phoneFrameBox" style={{width: height >= 1080 ? 'calc(1080px * 0.582)' : 'calc(100vh * 0.582)'}}>
                <div className="phoneFrameFixed">
                    <div className="phoneFrame"/>
                </div>
                <div className="phoneFrameInner">
                    <div className="phoneContainer">
                        {children}
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- main ends here --> */}
        {/* <Footer {...children.props}/> */}
    </>
}