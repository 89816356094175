import Head from "next/head";
import { appWithTranslation } from "next-i18next";
import store, { persistor } from "@/store";
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { useEffect, useRef, useState } from "react";
import Layout from "@/components/layout";
import Maintenance from "@/components/maintenance";
import MobileLayout from "@/components/mobile/layout";
import { MantineProvider } from "@mantine/core";
import * as Sentry from "@sentry/react";
import { useTranslation } from 'next-i18next';
import { useRouter } from "next/router";
import _ from 'lodash';
import { _getURL } from "./api/index";
import 'rsuite/dist/rsuite.min.css';
import "../../public/assets/css/styles.css"
import "../../public/assets/css/responsive.css"
import ErrorBoundary from "@/components/ErrorBoundary";
/** To support lower version */
import "../../public/assets/js/polyfills";
import ResizeObserver from 'resize-observer-polyfill';
import Script from "next/script";
import { pageviewGA } from "../libraries/google";
import { AllMeta } from '@/components/common/meta';
import { mobileViewWidth } from "../config/site";
import { FullScreenLoading } from "../components/common/loader";
import { removeIsWebviewPath } from "../libraries/common";
global.ResizeObserver = ResizeObserver;

if (process.env.NEXT_PUBLIC_ENV !== 'dev') {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    tracesSampleRate: 1.0, //lower the value in production
  });
}

Promise.allSettled = Promise.allSettled || ((promises) => Promise.all(promises.map(p => p
  .then(value => ({
    status: 'fulfilled', value
  }))
  .catch(reason => ({
    status: 'rejected', reason
  }))
)));

function MyApp(props) {
  const { Component, pageProps: { ...pageProps }, seo, isMobile = false } = props
  const mainRef = useRef();
  const router = useRouter();
  let [state, setState] = useState({ device: isMobile == true ? "mobile" : "desktop", isRunningAnalytics: false });

  const { t } = useTranslation('common');

  useEffect(() => {
    const fetchData = async () => {
      window.history.prevUrl = [];
      let detectWidthDevice = window.innerWidth <= mobileViewWidth ? "mobile" : "desktop";
      if(detectWidthDevice != state.device){
        setState({ device: detectWidthDevice })
      }
    }
    fetchData();
  }, [])

  useEffect(() => {
    if(window.innerWidth <= mobileViewWidth){
      window.scrollTo({
          top: 1,
          behavior: 'instant'
      });
    }
  }, [router]);

  useEffect(() => {
      const handleRouteChange = (url) => {
        pageviewGA(url)
      };
  
      router.events.on("routeChangeComplete", handleRouteChange);
  
      return () => {
        router.events.off("routeChangeComplete", handleRouteChange);
      };
  }, [router.events]);

  const getLayout = Component.getLayout || null;
  const newProps = { ...pageProps, session: { }, device: state.device,t };

  return <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {() => (
        <>
          <Head>
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
            <title>KeyAuto.my | 大马中文汽车网站 - 最新、最夯且全面的汽车资讯</title>
            <meta key="standard-title" name="title" content="KeyAuto.my | 大马中文汽车网站 - 最新、最夯且全面的汽车资讯" />
            <meta key="standard-description" name="description" content="KeyAuto.my 马来西亚中文汽车资讯网站, 用不同形貌与读者一起掌握汽车脉动. KeyAuto.my 将提供最新, 最夯且全面的汽车资讯, 主要频道包括国际与本地新闻、新车介绍、试驾评测、专题报道、新车价格、技术规格、配备、优惠、图库和搜索等一站式资讯。" />
            <link rel="apple-touch-icon" sizes="180x180" href="/assets/images/favicon/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/assets/images/favicon/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/assets/images/favicon/favicon-16x16.png" />
            <link rel="manifest" href="/assets/images/favicon/site.webmanifest" />
            <link rel="mask-icon" href="/assets/images/favicon/safari-pinned-tab.svg" color="#5bbad5" />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />
            <meta key="og:locale" property="og:locale" content="en_US" />
            <meta key="og:type" property="og:type" content="article" />
            <meta key="og:title" property="og:title" content="KeyAuto.my | 大马中文汽车网站 - 最新、最夯且全面的汽车资讯" />
            <meta key="og:description" property="og:description" content="KeyAuto.my 马来西亚中文汽车资讯网站, 用不同形貌与读者一起掌握汽车脉动. KeyAuto.my 将提供最新, 最夯且全面的汽车资讯, 主要频道包括国际与本地新闻、新车介绍、试驾评测、专题报道、新车价格、技术规格、配备、优惠、图库和搜索等一站式资讯。" />
            <meta key="og:url" property="og:url" content={typeof window !== 'undefined' ? `${window.location.origin}${removeIsWebviewPath(router.asPath)}` : undefined} />
            <meta key="og:site_name" property="og:site_name" content="KeyAuto.my" />
            <meta key="article:publisher" property="article:publisher" content="https://www.facebook.com/keyauto.my/" />
            <meta key="article:modified_time" property="article:modified_time" content="2022-11-25T02:14:21+00:00" />
            <meta key="og:image" property="og:image" content="/assets/images/keyauto.my-malaysia-chinese-automotive-website.png" />
            <meta key="og:image:width" property="og:image:width" content="512" />
            <meta key="og:image:height" property="og:image:height" content="512" />
            <meta key="og:image:type" property="og:image:type" content="image/png" />
            <link key="standard-canonical" rel="canonical" href={typeof window !== 'undefined' ? `${window.location.origin}${removeIsWebviewPath(router.asPath)}` : undefined} />
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3254799400709253" crossOrigin="anonymous"></script>
            <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WKWLNH2" height="0" width="0" style={{display:'none',visibility:'hidden'}}></iframe></noscript>
            <script
              dangerouslySetInnerHTML={{
                __html: `
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-WKWLNH2');
                `,
              }}
            />
          </Head>
          {/* over write the default, t cannot run here */}
          <AllMeta
            title={router?.locale == "en" ? "KeyAuto.my | Malaysia Automotive News and Car Buyer's Guide Platform" : "KeyAuto.my | 马来西亚汽车资讯、购买指南、服务平台"}
            description={router?.locale == "en" ? "Latest, most comprehensive automotive information. KeyAuto - global and local news, new car introductions, test drive reviews, new car prices, technical specifications, promotion, galleries and search, etc. all in one stop Information." : "最新、最全面的汽车资讯。 KeyAuto - 全球和本地新闻、新车介绍、试驾评论、新车价格、技术规格、促销、图库和搜索等一站式信息。"}
            keyword={router?.locale == "en" ? "KeyAuto Malaysia Automotive website" : "KeyAuto Malaysia 马来西亚车讯网"}
          />
          <Head><title>{router?.locale == "en" ? "KeyAuto.my | Malaysia Automotive News and Car Buyer's Guide Platform" : "KeyAuto.my | 马来西亚汽车资讯、购买指南、服务平台" }</title></Head>
          <ErrorBoundary>
            <MantineProvider>
              <Script strategy="afterInteractive" src="https://www.googletagmanager.com/gtag/js?id=G-CND79PDPEJ"/>
              <Script
                id='google-analytics'
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-CND79PDPEJ', {
                      page_path: window.location.pathname,
                    });
                  `,
                  }}
              />
              {/* <FullScreenLoading ref={mainRef} defaultLoadingState={true} /> */}
              {
                process.env.NEXT_PUBLIC_MAINTENANCE_MODE == 1 ?
                  <Maintenance />
                : getLayout ?
                    getLayout(<Component {...newProps} />) 
                : state.device == "desktop" ?
                    <Layout>
                      <Component {...newProps} />
                    </Layout>
                :
                  <MobileLayout>
                    <Component {...newProps} />
                  </MobileLayout>
              }
            </MantineProvider>
          </ErrorBoundary>
        </>
      )}
    </PersistGate>
  </Provider>
}

MyApp.getInitialProps = async (props) => {
  const { ctx } = props
  let isMobile = false;

  if (ctx.req) {
    const userAgent = ctx.req.headers['user-agent'];
    isMobile = /mobile/i.test(userAgent);
  }

  return { isMobile };
};

export default appWithTranslation(MyApp)