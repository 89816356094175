import ReactGA from 'react-ga';

// const TRACKING_ID = "G-CND79PDPEJ";

// export const initGA = () => { // can't work
//     ReactGA.initialize(TRACKING_ID);
// }

// export const logPageView = () => { // can't work
//     ReactGA.set({ page: window.location.pathname });
//     ReactGA.pageview(window.location.pathname);
// };

export const GA_MEASUREMENT_ID = "G-CND79PDPEJ";
// export const GA_MEASUREMENT_ID = "G-J62HS2M53N"; // Testing
 
export const pageviewGA = (url) => {
    window.gtag("config", GA_MEASUREMENT_ID, {
        page_path: url,
    });
};
 
export const eventGA = ({ action, category, label, value }) => {
    window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value,
    });
};