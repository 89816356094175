import { useMemo, useState } from "react";
import Redux, { updatePath } from "@/action"
import { useLayoutEffect, useEffect } from "react"
import { _getURL } from "@/api/index";
import { toast } from "@/libraries/swal";
import { useRouter } from "next/router";
import { mobileViewWidth } from "../../../config/site";

export default function Layout({ children }) {
    const { store, dispatch } = Redux();
    const { t, session={} } = children.props;
    const router = useRouter();

    useEffect(() => {
        
        function updateSize() {
            // window.innerHeight >= window.innerWidth make sure mobile device cannot reload when rotate (full screen video)
            if ((window.innerWidth > mobileViewWidth) && (window.innerHeight >= window.innerWidth)) {
                location.reload();
            }
        }
        window.addEventListener('resize', updateSize);
        
        const queryParams = new URLSearchParams(router.asPath.split('?')[1]);
        const isWebview = queryParams.get('isWebview');
        const isStandAlone = queryParams.get('isStandAlone');

        if(store?.path?.isLoadedInFlutterApp == undefined){
            dispatch(updatePath({ key: "isLoadedInFlutterApp", path: (isWebview == "true") ? true : false}));
            window.isLoadedInFlutterApp = (isWebview == "true") ? true : false;
        }
        if(store?.path?.isStandaloneInFlutterApp == undefined){
            dispatch(updatePath({ key: "isStandaloneInFlutterApp", path: (isStandAlone == "true") ? true : false}));
            window.isStandaloneInFlutterApp = (isStandAlone == "true") ? true : false;
        }
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return (
        <>
            <div className="mobileBodyContainer" 
                // style={{ height: vh }}
            >
                {children}
            </div>
        </>
    )
}

