import _ from 'lodash';
import { toast } from "@/libraries/swal";
import { _getURL } from "@/api/index";
import { _postURL } from '@/api/index';

/** Restore credit that no under promotion from provider to main wallet */
const _restoreMainWallet = async ({ dispatch, updatePath, showAlert=true }) => {
    try{
        let responseJson = await _restoreCredit();
        
        if(showAlert){
            toast.success(responseJson.message);
        }

        dispatch(updatePath({ key: "balances", path: null }));
        let balance = await _getMainWallet({ dispatch, updatePath });

        return { responseJson, balance };
    }
    catch(error){
        throw error;
    }
}

/** Retrieve main wallet balance and dispatch to redux */
const _getMainWallet = async ({ dispatch, updatePath }) => {
    try{
        let responseJson = await _getURL("/api/account/profile");
        let balance = responseJson && responseJson.data && responseJson.data.main_wallet?responseJson.data.main_wallet:0;
        
        dispatch(updatePath({ key: "main_wallet", path: parseFloat(balance) }));
        dispatch(updatePath({ key: "auto_transfer", path: responseJson.data.auto_transfer }));

        return parseFloat(balance);
    }
    catch(error){
        throw error;
    }
}

/** Transfer credit to provider system */
export const _transfer = async ({ data }) => {
    try {
        let result = await _postURL(`/api/transfer/create`, { ...data });

        return result;
    }
    catch (error) {
        throw error;
    }
};

/** Retrieve balance in provider system */
export const _getBalance = async ({ code, session, sys_currency }) => {
    try {
        let body = {
            username: session.user.user_id,
            sys_currency,
            mcode: process.env.NEXT_PUBLIC_MCODE
        };
        
        let result = await _postURL(`/api/transfer/balance?code=${code}`, body);

        return result.data;
    }
    catch (error) {
        throw error;
    }
};

/** Retrieve multiple balance in provider system */
export const _getBalances = async ({ codes, session, sys_currency }) => {
    try {
        let body = {
            pcodes: codes,
            username: session.user.user_id,
            sys_currency,
            mcode: process.env.NEXT_PUBLIC_MCODE
        };
        
        let result = await _postURL(`/api/transfer/balances`, body);

        return result.data;
    }
    catch (error) {
        throw error;
    }
};

/** Restore credit to promo wallet */
export const _restorePromoWallet = async ({ promo_wallet_id }) => {
    try {
        let result = await _postURL(`/api/transfer/restorePromo`, { promo_wallet_id });

        return result;
    }
    catch (error) {
        throw error;
    }
}

/** Restore Credit */
export const _restoreCredit = async () => {
    try {
        let result = await _postURL(`/api/transfer/restore`, { timeout: 60000 }, { timeout: 60000 });

        return result;
    }
    catch (error) {
        throw error;
    }
}

export default {
    _restoreMainWallet,
    _getMainWallet
}